import React, { useEffect, useState } from "react";
import "./AddUserModal.css";
import "./DeleteEmployeeModal.css";
import { getCompanyEmployees } from "../axios/Employees";
import { fetchCompanies } from "../axios/Companies";
import { getHistoricalEmployeeTrainingReport } from "../axios/Reports"; // You'll need to create this
import { getCompanyTrainings } from "../axios/Trainings";
import dayjs from "dayjs";

// Material Imports
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import Alert from "@mui/material/Alert";
import Snackbar from "@mui/material/Snackbar";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "50%",
  height: "25%",
  backgroundColor: "background.paper",
  border: "4px transparent #000",
  boxShadow: 24,
  p: 4,
  borderRadius: ".5rem",
};

const textFieldStyle = {
  marginTop: ".4rem",
  marginBottom: ".4rem",
  width: "100%",
};

const PrintOrCancel = styled(Button)({
  fontFamily: "Poppins",
  backgroundColor: "#21357B",
  margin: "10px",
  textTransform: "none",
});

export default function PrintEmployeeHistoricalTrainingReport({
  isOpen,
  handleClose,
  reportInfo,
  setReportInfo,
}) {
  const [allCompanies, setAllCompanies] = useState([]);
  const [selectedCompany, setSelectedCompany] = useState("");
  const [selectedEmployee, setSelectedEmployee] = useState("");
  const [employees, setEmployees] = useState([]);
  const [trainings, setTrainings] = useState([]);
  const [alertState, setAlertState] = useState({
    open: false,
    message: "",
    severity: "info",
  });

  const fetchCompaniesAndSave = async () => {
    try {
      const companies = await fetchCompanies();
      setAllCompanies(companies);
    } catch (error) {
      console.error("Error fetching companies:", error);
    }
  };

  const fetchEmployees = async (companyId) => {
    try {
      const data = await getCompanyEmployees(companyId);
      const currentEmployees = data.filter(
        (employee) => employee.isHidden !== true && employee.isHidden !== "true"
      );
      setEmployees(
        currentEmployees.sort((a, b) => a.lastName.localeCompare(b.lastName))
      );
    } catch (error) {
      console.error("Error fetching employees:", error);
    }
  };

  const handleCancelOrPrint = (e) => {
    if (e.target.name === "print") {
      getReport();
    }
    handleClose();
    setReportInfo({});
    setSelectedCompany("");
    setSelectedEmployee("");
  };

  const fetchTrainings = async (companyId) => {
    try {
      await getCompanyTrainings({
        companyId: companyId,
        setTrainings: setTrainings,
        mapTrainings: (data) => {
          return data;
        },
        setRows: () => {},
      });
    } catch (error) {
      console.error("Error fetching trainings:", error);
    }
  };

  const getReport = async () => {
    try {
      const employeeTrainingData = trainings
        .filter((training) =>
          training.attendees.some(
            (attendee) => attendee.driverId === selectedEmployee
          )
        )
        .flatMap((training) =>
          training.courses.map((courseName) => ({
            trainingDate: dayjs(training.trainingDate).format("MM-DD-YYYY"),
            instructor: training.instructor,
            courseName,
          }))
        );

      if (employeeTrainingData.length === 0) {
        setAlertState({
          open: true,
          message: "No training records found for this employee",
          severity: "info",
        });
        return;
      }

      const selectedEmployeeData = employees.find(
        (emp) => emp._id === selectedEmployee
      );

      if (!selectedEmployeeData) {
        throw new Error("Selected employee not found");
      }

      const reportData = {
        company: {
          companyName: reportInfo.companyName,
          companyId: reportInfo.companyId,
        },
        employee: {
          employeeId: selectedEmployee,
          employeeName: `${selectedEmployeeData.lastName}, ${selectedEmployeeData.firstName}`,
          isDriver: selectedEmployeeData.isDriver,
        },
        trainings: employeeTrainingData,
      };

      await getHistoricalEmployeeTrainingReport(reportData);
    } catch (error) {
      console.error("Error getting historical training report:", error);
      setAlertState({
        open: true,
        message: "An error occurred while generating the report",
        severity: "error",
      });
    }
  };

  const handleAlertClose = () => {
    setAlertState({ ...alertState, open: false });
  };

  useEffect(() => {
    fetchCompaniesAndSave();
  }, []);

  useEffect(() => {
    if (selectedCompany) {
      fetchEmployees(selectedCompany);
      fetchTrainings(selectedCompany);
    }
  }, [selectedCompany]);

  return (
    <div>
      <Modal open={isOpen} onClose={handleClose}>
        <Box sx={style} className="boxStyle">
          <h2 style={{ textAlign: "center" }}>
            Employee Historical Training Report
          </h2>
          <div className="inputContainer">
            <div className="formRow">
              <div className="formCell">Select Company</div>
              <Select
                size="small"
                fullWidth
                name="companyName"
                sx={textFieldStyle}
                value={selectedCompany}
                onChange={(e) => {
                  const selected = allCompanies.find(
                    (company) => company._id === e.target.value
                  );
                  setReportInfo({
                    companyName: selected.companyName,
                    companyId: selected._id,
                  });
                  setSelectedCompany(selected._id);
                  setSelectedEmployee("");
                }}
              >
                {allCompanies.map(({ companyName, _id }) => (
                  <MenuItem key={_id} value={_id}>
                    {companyName}
                  </MenuItem>
                ))}
              </Select>
            </div>
            <div className="formRow">
              <div className="formCell">Select Employee</div>
              <Select
                size="small"
                fullWidth
                name="employee"
                sx={textFieldStyle}
                value={selectedEmployee}
                onChange={(e) => setSelectedEmployee(e.target.value)}
                disabled={!selectedCompany}
              >
                {employees.map((employee) => (
                  <MenuItem key={employee._id} value={employee._id}>
                    {`${employee.lastName}, ${employee.firstName}`}
                  </MenuItem>
                ))}
              </Select>
            </div>
          </div>

          <div id="deleteOrCancel">
            <div>
              <PrintOrCancel
                onClick={handleCancelOrPrint}
                name="cancel"
                variant="contained"
              >
                Cancel
              </PrintOrCancel>
              <PrintOrCancel
                onClick={handleCancelOrPrint}
                name="print"
                variant="contained"
                disabled={!selectedEmployee}
              >
                Print
              </PrintOrCancel>
            </div>
          </div>
        </Box>
      </Modal>
      <Snackbar
        open={alertState.open}
        autoHideDuration={6000}
        onClose={handleAlertClose}
      >
        <Alert onClose={handleAlertClose} severity={alertState.severity}>
          {alertState.message}
        </Alert>
      </Snackbar>
    </div>
  );
}

import api from "./BaseUrl";

export const getRemindersReport = async (reportData) => {
  try {
    const response = await api.post(
      `/api/reports/generate/reminders`,
      reportData,
      {
        responseType: "blob", // Response type set to 'blob' to handle binary data
      }
    );

    const url = window.URL.createObjectURL(new Blob([response.data]));

    const link = document.createElement("a");
    link.href = url;
    link.setAttribute(
      "download",
      `${reportData.companies[0].reminderType}RemindersReport.pdf`
    );
    document.body.appendChild(link);
    link.click();
    link.parentNode.removeChild(link);
  } catch (error) {
    console.error(
      "Failed to download the report:",
      error.response || error.message || error
    );
  }
};

export const getScreeningsReport = async (reportData) => {
  try {
    const response = await api.post(
      `/api/reports/generate/screenings`,
      reportData,
      {
        responseType: "blob",
      }
    );

    const url = window.URL.createObjectURL(new Blob([response.data]));

    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", "screeningsReport.pdf");
    document.body.appendChild(link);
    link.click();
    link.parentNode.removeChild(link);
  } catch (error) {
    console.error(
      "Failed to download the report:",
      error.response || error.message || error
    );
  }
};

// TODO: Update this to get all relevant data
export const getScreeningsSummaryReport = async (reportData) => {
  try {
    const response = await api.post(
      `/api/reports/generate/screenings/summary`,
      reportData,
      {
        responseType: "blob",
      }
    );

    const url = window.URL.createObjectURL(new Blob([response.data]));

    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", "screeningsSummaryReport.pdf");
    document.body.appendChild(link);
    link.click();
    link.parentNode.removeChild(link);
  } catch (error) {
    console.error(
      "Failed to download the report:",
      error.response || error.message || error
    );
  }
};

export const getCompanyTrainingsReport = async (reportData) => {
  try {
    const response = await api.post(
      `/api/reports/generate/company/trainings`,
      reportData,
      {
        responseType: "blob",
      }
    );

    const url = window.URL.createObjectURL(new Blob([response.data]));

    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", "companyTrainingsReport.pdf");
    document.body.appendChild(link);
    link.click();
    link.parentNode.removeChild(link);
  } catch (error) {
    console.error(
      "Failed to download the report:",
      error.response || error.message || error
    );
  }
};

export const getAnnualCompaniesAddedReport = async (reportData) => {
  try {
    const response = await api.post(
      `/api/reports/generate/companies`,
      reportData,
      {
        responseType: "blob",
      }
    );

    const url = window.URL.createObjectURL(new Blob([response.data]));

    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", "annualCompaniesAddedReport.pdf");
    document.body.appendChild(link);
    link.click();
    link.parentNode.removeChild(link);
  } catch (error) {
    console.error(
      "Failed to download the report:",
      error.response || error.message || error
    );
  }
};

// TODO: Update this to get all relevant data
export const getAnnualScreeningsReport = async (reportData) => {
  try {
    const response = await api.post(
      `/api/reports/generate/screenings/annual`,
      reportData,
      {
        responseType: "blob",
      }
    );

    const url = window.URL.createObjectURL(new Blob([response.data]));

    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", "annualScreeningsReport.pdf");
    document.body.appendChild(link);
    link.click();
    link.parentNode.removeChild(link);
  } catch (error) {
    console.error(
      "Failed to download the report:",
      error.response || error.message || error
    );
  }
};

export const getCompanyEmployeesReport = async (reportData) => {
  try {
    const response = await api.post(
      `api/reports/generate/company/employees`,
      reportData,
      {
        responseType: "blob",
      }
    );

    const url = window.URL.createObjectURL(new Blob([response.data]));

    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", "companyEmployeesReport.pdf");
    document.body.appendChild(link);
    link.click();
    link.parentNode.removeChild(link);
  } catch (error) {
    console.error(
      "Failed to download the report:",
      error.response || error.message || error
    );
  }
};

export const getHistoricalEmployeeTrainingReport = async (reportData) => {
  try {
    const response = await api.post(
      `/api/reports/generate/company/employee/training`,
      reportData,
      {
        responseType: "blob",
      }
    );

    const url = window.URL.createObjectURL(new Blob([response.data]));

    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", "historicalEmployeeTrainingReport.pdf");
    document.body.appendChild(link);
    link.click();
    link.parentNode.removeChild(link);
  } catch (error) {
    console.error(
      "Failed to download the report:",
      error.response || error.message || error
    );
  }
};

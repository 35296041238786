import React, {
  useState,
  useEffect,
  useRef,
  forwardRef,
  Fragment,
} from "react";
import dayjs from "dayjs";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import { styled } from "@mui/material/styles";
import TextField from "@mui/material/TextField";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import Button from "@mui/material/Button";
import Autocomplete from "@mui/material/Autocomplete";
import Chip from "@mui/material/Chip";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import IconButton from "@mui/material/IconButton";
import ClearIcon from "@mui/icons-material/Clear";
import ForwardToInboxIcon from "@mui/icons-material/ForwardToInbox";

import EmployeeTransferList from "./TransferList";
import { EmployeesTooltip } from "./employees/employeesTooltip";
import { ChildModal } from "./modals/AddTrainingModal";
import { emptyRemindersArray } from "./BoilerplateObjects";
import { createReminders, updateReminders } from "./axios/Reminders";
import "./TableToolbar.css";
// User Session
import { isAdmin } from "./axios/UserSession";

const RefTooltip = forwardRef((props, ref) => <Tooltip ref={ref} {...props} />);

const StyledDetailsTooltip = styled(({ className, ...props }) => (
  <RefTooltip {...props} classes={{ popper: className }} />
))({
  [`& .${tooltipClasses.tooltip}`]: {
    maxWidth: "none",
    color: "black",
    backgroundColor: "white",
    fontSize: "14px",
    fontFamily: "Poppins",
    padding: "0px 0px 0px 20px",
    margin: "0px 0px 0px 25px",
    position: "relative",
    maxHeight: "60vh",
    overflowY: "auto",
    display: "flex",
    flexDirection: "column",
    "&::-webkit-scrollbar": {
      width: "8px",
    },
    "&::-webkit-scrollbar-track": {
      background: "#f1f1f1",
      borderRadius: "4px",
    },
    "&::-webkit-scrollbar-thumb": {
      background: "#888",
      borderRadius: "4px",
      "&:hover": {
        background: "#555",
      },
    },
  },
});

const SaveOrCancel = styled(Button)({
  fontFamily: "Poppins",
  backgroundColor: "#21357B",
  margin: "10px",
  textTransform: "none",
});

const ProgramsForm = styled(FormGroup)({
  display: "flex",
  flexDirection: "row",
  width: "99%",
});

const ProgramsLabel = styled(FormControlLabel)({
  marginRight: "30px",
});

const textFieldStyle = {
  marginTop: ".4rem",
  marginBottom: ".4rem",
  width: "100%",
};

const defaultReminders = {
  USDOT: {
    completedDate: null,
    dueDate: null,
    isLinscotManaged: false,
    isHidden: true,
  },
  UCR: {
    completedDate: null,
    dueDate: null,
    isLinscotManaged: false,
    isHidden: true,
  },
  RIN: {
    completedDate: null,
    dueDate: null,
    isLinscotManaged: false,
    isHidden: true,
  },
  2290: {
    completedDate: null,
    dueDate: null,
    isLinscotManaged: false,
    isHidden: true,
  },
  CAB: {
    completedDate: null,
    dueDate: null,
    isLinscotManaged: false,
    isHidden: true,
  },
  USHAZ: {
    completedDate: null,
    dueDate: null,
    isLinscotManaged: false,
    hazmatRegistrationInterval: 1,
    isHidden: true,
  },
};

function arrayToObject(arr, defaultObj) {
  if (!arr || arr.length === 0) {
    return { ...defaultObj };
  }

  return arr.reduce(
    (obj, item) => {
      obj[item.reminderType] = {
        ...defaultObj[item.reminderType],
        isLinscotManaged: item.isLinscotManaged ? item.isLinscotManaged : false,
        completedDate: item.completedDate ? dayjs(item.completedDate) : null,
        dueDate: item.dueDate ? dayjs(item.dueDate) : null,
        hazmatRegistrationInterval: item.hazmatRegistrationInterval
          ? item.hazmatRegistrationInterval
          : null,
      };
      return obj;
    },
    { ...defaultObj }
  );
}

function filterAndMapArray(array) {
  return array.map((obj) => ({
    driverId: obj.driverId || obj._id,
    firstName: obj.firstName,
    lastName: obj.lastName,
  }));
}

export function DetailsTooltip({ props, children }) {
  let {
    actionCategory,
    checked,
    formInfo,
    allEmployees,
    updateAttendees,
    totalAttendees,
    isDetailsOpen,
    handleAlertSuccess,
    detailsOpen,
    handleChange,
    handleCancelOrSave,
    values,
    setChecked,
    setSelectedCourses,
    allCourses,
    selectedCourses,
    setAllCourses,
    allInstructors,
    companyReminders,
  } = props;

  const tooltipRef = useRef(null);
  const remindersRef = useRef(emptyRemindersArray);

  const [reminderDates, setReminderDates] = useState(
    arrayToObject(companyReminders || [], defaultReminders)
  );

  const [hazmatRegistrationInterval, setHazmatRegistrationInterval] =
    useState(1);

  const remindersArray = [
    {
      reminderType: "USDOT",
      completedDate: reminderDates.USDOT.completedDate,
      dueDate: reminderDates.USDOT.dueDate,
      isLinscotManaged: reminderDates.USDOT.isLinscotManaged,
      isHidden: reminderDates.USDOT.isHidden,
    },
    {
      reminderType: "UCR",
      completedDate: reminderDates.UCR.completedDate,
      dueDate: reminderDates.UCR.dueDate,
      isLinscotManaged: reminderDates.UCR.isLinscotManaged,
      isHidden: reminderDates.UCR.isHidden,
    },
    {
      reminderType: "RIN",
      completedDate: reminderDates.RIN.completedDate,
      dueDate: reminderDates.RIN.dueDate,
      isLinscotManaged: reminderDates.RIN.isLinscotManaged,
      isHidden: reminderDates.RIN.isHidden,
    },
    {
      reminderType: "CAB",
      completedDate: reminderDates.CAB.completedDate,
      dueDate: reminderDates.CAB.dueDate,
      isLinscotManaged: reminderDates.CAB.isLinscotManaged,
      isHidden: reminderDates.CAB.isHidden,
    },
    {
      reminderType: "2290",
      completedDate: reminderDates["2290"].completedDate,
      dueDate: reminderDates["2290"].dueDate,
      isLinscotManaged: reminderDates["2290"].isLinscotManaged,
      isHidden: reminderDates["2290"].isHidden,
    },
    {
      reminderType: "USHAZ",
      completedDate: reminderDates.USHAZ.completedDate,
      dueDate: reminderDates.USHAZ.dueDate,
      isLinscotManaged: reminderDates.USHAZ.isLinscotManaged,
      hazmatRegistrationInterval,
      isHidden: reminderDates.USHAZ.isHidden,
    },
  ];

  const saveReminders = () => {
    let HazmatRegistrationInterval = null;

    // Iterate through current reminders array
    remindersArray.forEach((currentReminder) => {
      // If the current reminder completed date is null, skip it
      if (currentReminder.completedDate === null) {
        return;
      }

      // Iterate through the initial reminders array
      remindersRef.current.forEach((previousReminder) => {
        // Check if the reminder types match
        if (currentReminder.reminderType === previousReminder.reminderType) {
          // Set the USHAZ interval if applicable
          if (currentReminder.reminderType === "USHAZ") {
            HazmatRegistrationInterval = hazmatRegistrationInterval;
          }

          // If the previous reminder completed date is null, create a new reminder
          if (previousReminder.completedDate === null) {
            try {
              createReminders({
                reminderData: {
                  reminderType: currentReminder.reminderType,
                  dueDate: currentReminder.dueDate,
                  companyName: formInfo.companyName,
                  companyId: formInfo.companyId,
                  isHidden: currentReminder.isHidden,
                  completedDate: currentReminder.completedDate,
                  hazmatRegistrationInterval: HazmatRegistrationInterval,
                  isLinscotManaged: currentReminder.isLinscotManaged,
                },
              });
            } catch (error) {
              console.error("Error creating reminder:", error);
            }
          } else if (
            currentReminder.completedDate !== previousReminder.completedDate
          ) {
            // If the completed dates differ, update the existing reminder
            try {
              updateReminders(previousReminder._id, {
                reminderType: previousReminder.reminderType,
                dueDate: currentReminder.dueDate,
                companyName: previousReminder.companyName,
                companyId: previousReminder.companyId,
                firstName: '"',
                lastName: "",
                employeeId: "",
                message: "",
                isHidden: currentReminder.isHidden,
                completedDate: currentReminder.completedDate,
                hazmatRegistrationInterval: HazmatRegistrationInterval,
                isLinscotManaged: currentReminder.isLinscotManaged,
              });
            } catch (error) {
              console.error("Error updating reminder:", error);
            }
          }
        }
      });
    });
  };

  const calculateDueDate = (startDate, intervalYears) =>
    dayjs(startDate).add(intervalYears, "year");

  const handleCompletedDateChange = (date, name) => {
    const timestamp = date ? date.toDate() : null;
    let newDueDate;
    let newCompletedDate;

    switch (name) {
      case "rinCompleted":
        newDueDate = timestamp ? calculateDueDate(dayjs(timestamp), 5) : null;
        newCompletedDate = timestamp ? dayjs(timestamp) : null;
        setReminderDates((prevState) => ({
          ...prevState,
          RIN: {
            ...prevState.RIN,
            completedDate: newCompletedDate,
            dueDate: newDueDate,
          },
        }));
        break;
      case "ucrCompleted":
        newDueDate = timestamp ? calculateDueDate(dayjs(timestamp), 1) : null;
        newCompletedDate = timestamp ? dayjs(timestamp) : null;
        setReminderDates((prevState) => ({
          ...prevState,
          UCR: {
            ...prevState.UCR,
            completedDate: newCompletedDate,
            dueDate: newDueDate,
          },
        }));
        break;
      case "hazmatRegistrationCompleted":
        newDueDate = calculateDueDate(
          dayjs(timestamp),
          hazmatRegistrationInterval
        );
        newCompletedDate = dayjs(timestamp);
        setReminderDates((prevState) => ({
          ...prevState,
          USHAZ: {
            ...prevState.USHAZ,
            completedDate: newCompletedDate,
            dueDate: newDueDate,
            hazmatRegistrationInterval,
          },
        }));
        break;
      case "twentyTwoNinetyCompleted":
        newDueDate = timestamp ? calculateDueDate(dayjs(timestamp), 1) : null; // // They will manually have to change the due date to June here
        newCompletedDate = timestamp ? dayjs(timestamp) : null;
        setReminderDates((prevState) => ({
          ...prevState,
          2290: {
            ...prevState["2290"],
            completedDate: newCompletedDate,
            dueDate: newDueDate,
          },
        }));
        break;
      case "usDotCompleted":
        newDueDate = timestamp ? calculateDueDate(dayjs(timestamp), 2) : null;
        newCompletedDate = timestamp ? dayjs(timestamp) : null;
        setReminderDates((prevState) => ({
          ...prevState,
          USDOT: {
            ...prevState.USDOT,
            completedDate: newCompletedDate,
            dueDate: newDueDate,
          },
        }));
        break;
      case "cabCardCompleted":
        newDueDate = timestamp ? calculateDueDate(dayjs(timestamp), 2) : null;
        newCompletedDate = timestamp ? dayjs(timestamp) : null;
        setReminderDates((prevState) => ({
          ...prevState,
          CAB: {
            ...prevState.CAB,
            completedDate: newCompletedDate,
            dueDate: newDueDate,
          },
        }));
        break;
      default:
        break;
    }
  };

  const handleDueDateChange = (date, name) => {
    const timestamp = date
      ? dayjs(date.$d)
          .hour(12) // Set to noon
          .minute(0)
          .second(0)
          .millisecond(0)
          .tz("US/Central")
      : null; // Convert to Central Time

    switch (name) {
      case "rinDue":
        setReminderDates((prevState) => ({
          ...prevState,
          RIN: {
            ...prevState.RIN,
            dueDate: timestamp,
          },
        }));
        break;
      case "ucrDue":
        setReminderDates((prevState) => ({
          ...prevState,
          UCR: {
            ...prevState.UCR,
            dueDate: timestamp,
          },
        }));
        break;
      case "hazmatRegistrationDue":
        setReminderDates((prevState) => ({
          ...prevState,
          USHAZ: {
            ...prevState.USHAZ,
            dueDate: timestamp,
          },
        }));
        break;
      case "twentyTwoNinetyDue":
        setReminderDates((prevState) => ({
          ...prevState,
          2290: {
            ...prevState["2290"],
            dueDate: timestamp,
          },
        }));
        break;
      case "usDotDue":
        setReminderDates((prevState) => ({
          ...prevState,
          USDOT: {
            ...prevState.USDOT,
            dueDate: timestamp,
          },
        }));
        break;
      case "cabCardDue":
        setReminderDates((prevState) => ({
          ...prevState,
          CAB: {
            ...prevState.CAB,
            dueDate: timestamp,
          },
        }));
        break;
      default:
        break;
    }
  };

  const handleCompanyRemindersCheckboxChange = (event, reminderType) => {
    const { checked } = event.target;

    setReminderDates((prevReminderDates) => ({
      ...prevReminderDates,
      [reminderType]: {
        ...prevReminderDates[reminderType],
        isHidden: !checked,
      },
    }));
  };

  const mapCurrentReminders = (array1, array2) => {
    if (
      !array1 ||
      array1.length === 0 ||
      !array2 ||
      array2.current.length === 0
    ) {
      return;
    }

    const remindersMap = array1.reduce((map, reminder) => {
      map[reminder.reminderType] = reminder;
      return map;
    }, {});

    array2.current = array2.current.map((reminder) => {
      const updatedReminder = remindersMap[reminder.reminderType];
      if (updatedReminder) {
        if (updatedReminder.reminderType !== "USHAZ") {
          return {
            ...reminder,
            completedDate: updatedReminder.completedDate,
            dueDate: updatedReminder.dueDate,
            isHidden: updatedReminder.isHidden,
            companyId: updatedReminder.companyId,
            companyName: updatedReminder.companyName,
            _id: updatedReminder._id,
          };
        } else {
          if (updatedReminder.hazmatRegistrationInterval) {
            setHazmatRegistrationInterval(
              updatedReminder.hazmatRegistrationInterval
            );
          }
          return {
            ...reminder,
            completedDate: updatedReminder.completedDate,
            dueDate: updatedReminder.dueDate,
            isHidden: updatedReminder.isHidden,
            companyId: updatedReminder.companyId,
            companyName: updatedReminder.companyName,
            _id: updatedReminder._id,
            hazmatRegistrationInterval:
              updatedReminder.hazmatRegistrationInterval,
          };
        }
      }
      return reminder;
    });
  };

  const handleClickOutside = (event) => {
    if (tooltipRef.current && !tooltipRef.current.contains(event.target)) {
      // Check if the clicked element is located on the tooltip
      const DontCloseElement =
        event.target.classList.contains("DontClose") ||
        event.target.classList.contains("MuiAutocomplete-option") ||
        event.target.classList.contains("MuiInputBase-input") ||
        event.target.classList.contains("MuiModal-backdrop") ||
        event.target.classList.contains("MuiPickersDay-root") ||
        event.target.classList.contains("addCourseButton") ||
        event.target.classList.contains("MuiPopper-root") ||
        event.target.classList.contains("MuiTooltip-popper") ||
        event.target.name === "cancelModal" ||
        event.target.name === "saveModal" ||
        event.target.classList.contains("MuiAutocomplete-popper") ||
        event.target.classList.contains("MuiPickersCalendarHeader-label") ||
        event.target.classList.contains("MuiModal-backdrop") ||
        event.target.classList.contains("MuiTooltip-popper") ||
        event.target.classList.contains("MuiPickersDay-root") ||
        event.target.classList.contains("DontClose") ||
        event.target.classList.contains("MuiPickersCalendarHeader-label") ||
        event.target.classList.contains("MuiDayCalendar-weekDayLabel") ||
        event.target.classList.contains(
          "MuiPickersCalendarHeader-labelContainer"
        ) ||
        event.target.classList.contains("MuiPickersCalendarHeader-root") ||
        event.target.classList.contains("MuiDayCalendar-header") ||
        event.target.classList.contains("MuiPickersYear-yearButton") ||
        event.target.classList.contains("MuiPickersCalendar-root") ||
        event.target.classList.contains("MuiIconButton-root") ||
        event.target.classList.contains("MuiPickersYear-root") ||
        event.target.classList.contains("MuiSvgIcon-root") ||
        event.target.classList.contains("MuiDayCalendar-weekContainer") ||
        event.target.classList.contains("MuiDateCalendar-root") ||
        event.target.classList.contains("MuiPickersFadeTransitionGroup-root") ||
        event.target.classList.contains("MuiPickersArrowSwitcher-root") ||
        event.target.classList.contains("MuiPickersArrowSwitcher-spacer") ||
        event.target.classList.contains("MuiDayCalendar-monthContainer") ||
        event.target.classList.contains(
          "MuiPickersCalendarHeader-switchViewIcon"
        );
      // If it's part of the form component, don't close the tooltip
      if (!DontCloseElement) {
        isDetailsOpen(false);
      }
    }
  };

  const handleYearChange = (event) => {
    const newInterval = event.target.value;
    setHazmatRegistrationInterval(newInterval);
    if (reminderDates.USHAZ.completedDate) {
      const newDueDate = calculateDueDate(
        reminderDates.USHAZ.completedDate,
        newInterval
      );
      setReminderDates((prevState) => ({
        ...prevState,
        USHAZ: {
          ...prevState.USHAZ,
          dueDate: newDueDate,
        },
      }));
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  });

  useEffect(() => {
    mapCurrentReminders(companyReminders, remindersRef);
  }, []);

  useEffect(() => {
    remindersRef.current = emptyRemindersArray;
    if (companyReminders) {
      const initialReminderDates = arrayToObject(
        companyReminders,
        defaultReminders
      );

      const updatedReminderDates = Object.keys(initialReminderDates).reduce(
        (acc, reminderType) => {
          const reminder = initialReminderDates[reminderType];
          if (reminder.completedDate || reminder.dueDate) {
            acc[reminderType] = {
              ...reminder,
              isHidden: false,
            };
          } else {
            acc[reminderType] = reminder;
          }
          return acc;
        },
        {}
      );

      setReminderDates(updatedReminderDates);
      mapCurrentReminders(companyReminders, remindersRef);
    }
  }, [formInfo, companyReminders]);

  if (
    Object.keys(formInfo).length <= 0 ||
    Object.keys(formInfo).length === undefined ||
    Object.keys(formInfo).length === null
  ) {
    return <React.Fragment>{children}</React.Fragment>;
  } else if (actionCategory === "Company" && Object.keys(formInfo).length > 0) {
    let {
      dotNumber,
      companyName,
      address,
      city,
      state,
      zip,
      phone,
      fax,
      email,
      contact,
      companyNotes,
      id,
      updatedAt,
      updatedBy,
      uCR,
      rIN,
      usDotHazmatRegistration,
      cabCard,
      twentyTwoNinety,
    } = formInfo;

    const generateMailToLink = (email) => {
      return `mailto:${email}`;
    };

    return (
      <StyledDetailsTooltip
        title={
          <div ref={tooltipRef} className="tooltipFormBox">
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <div className="tooltipFormHeader">
                <h3>{actionCategory} Details</h3>
                <IconButton
                  aria-label="close"
                  onClick={() => {
                    isDetailsOpen(false);
                  }}
                  sx={{
                    position: "absolute",
                    right: 8,
                    top: 8,
                    color: (theme) => theme.palette.grey[500],
                  }}
                >
                  <ClearIcon />
                </IconButton>
              </div>
              <div className="tooltipFormRow">
                <div className="tooltipFormCell">Name</div>
                <TextField
                  name="companyName"
                  onChange={(e) => {
                    handleChange(e, { values, setChecked });
                  }}
                  fullWidth
                  defaultValue={companyName}
                  size="small"
                  variant="outlined"
                />
              </div>
              <div className="tooltipFormRow">
                <div className="tooltipFormCell">Address</div>
                <TextField
                  name="address"
                  onChange={(e) => {
                    handleChange(e, { values, setChecked });
                  }}
                  fullWidth
                  defaultValue={address}
                  size="small"
                  variant="outlined"
                />
              </div>
              <div className="tooltipCSZRow">
                <div className="tooltipCSZCell">
                  <div className="tooltipFormCell">City</div>
                  <TextField
                    name="city"
                    onChange={(e) => {
                      handleChange(e, { values, setChecked });
                    }}
                    fullWidth
                    defaultValue={city}
                    style={{
                      marginLeft: "20px",
                    }}
                    size="small"
                    variant="outlined"
                  />
                </div>
                <div className="tooltipCSZCell">
                  <div className="tooltipZipInput">State</div>
                  <TextField
                    name="state"
                    onChange={(e) => {
                      handleChange(e, { values, setChecked });
                    }}
                    fullWidth
                    defaultValue={state}
                    size="small"
                    variant="outlined"
                  />
                </div>
                <div className="tooltipZip">
                  <div className="tooltipFormCell">Zip</div>
                  <TextField
                    name="zip"
                    onChange={(e) => {
                      handleChange(e, { values, setChecked });
                    }}
                    fullWidth
                    defaultValue={zip}
                    size="small"
                    variant="outlined"
                  />
                </div>
              </div>
              <div className="tooltipFormRow">
                <div className="tooltipFormCell">Phone</div>
                <TextField
                  name="phone"
                  onChange={(e) => {
                    handleChange(e, { values, setChecked });
                  }}
                  fullWidth
                  defaultValue={phone}
                  size="small"
                  variant="outlined"
                />
              </div>
              <div className="tooltipFormRow">
                <div className="tooltipFormCell">Fax</div>
                <TextField
                  name="fax"
                  onChange={(e) => {
                    handleChange(e, { values, setChecked });
                  }}
                  fullWidth
                  defaultValue={fax}
                  size="small"
                  variant="outlined"
                />
              </div>
              <div className="tooltipFormRow">
                <div className="tooltipFormCell">Contact</div>
                <TextField
                  name="contact"
                  onChange={(e) => {
                    handleChange(e, { values, setChecked });
                  }}
                  fullWidth
                  defaultValue={contact}
                  size="small"
                  variant="outlined"
                />
              </div>
              <div className="tooltipFormRow">
                <div className="tooltipFormCell">Email</div>
                <TextField
                  name="email"
                  onChange={(e) => {
                    handleChange(e, { values, setChecked });
                  }}
                  fullWidth
                  defaultValue={email}
                  size="small"
                  variant="outlined"
                />
                <Tooltip title="Email">
                  <IconButton
                    disabled={email ? false : true}
                    variant="outlined"
                    href={generateMailToLink(email)}
                  >
                    <ForwardToInboxIcon />
                  </IconButton>
                </Tooltip>
              </div>
              <div className="tooltipFormRow">
                <div className="tooltipFormCell">ID</div>
                <TextField
                  disabled
                  name="companyId"
                  fullWidth
                  defaultValue={id}
                  size="small"
                  variant="outlined"
                />
              </div>
              <div className="tooltipFormRow">
                <div className="tooltipFormCell">Programs</div>
                <ProgramsForm>
                  <ProgramsLabel
                    control={
                      <Checkbox
                        sx={{
                          "&.Mui-checked": {
                            color: "#21357B",
                          },
                        }}
                        checked={checked.alcoholAndDrug}
                        name="alcoholAndDrug"
                        onChange={(e) => {
                          handleChange(e, { values, setChecked, checked });
                        }}
                      />
                    }
                    label="Alcohol/Drug"
                  />

                  <ProgramsLabel
                    control={
                      <Checkbox
                        sx={{
                          "&.Mui-checked": {
                            color: "#21357B",
                          },
                        }}
                        checked={checked.training}
                        name="training"
                        onChange={(e) => {
                          handleChange(e, { values, setChecked, checked });
                        }}
                      />
                    }
                    label="Training"
                  />

                  <ProgramsLabel
                    control={
                      <Checkbox
                        sx={{
                          "&.Mui-checked": {
                            color: "#21357B",
                          },
                        }}
                        checked={checked.reminders}
                        name="reminders"
                        onChange={(e) => {
                          handleChange(e, { values, setChecked, checked });
                        }}
                      />
                    }
                    label="Reminders"
                  />

                  <ProgramsLabel
                    control={
                      <Checkbox
                        sx={{
                          "&.Mui-checked": {
                            color: "#21357B",
                          },
                        }}
                        checked={checked.clearinghouse}
                        name="clearinghouse"
                        onChange={(e) => {
                          handleChange(e, { values, setChecked, checked });
                        }}
                      />
                    }
                    label="Clearinghouse"
                  />
                </ProgramsForm>
              </div>
              <div className="tooltipCSZRow">
                <div className="tooltipCSZCell">
                  <div className="tooltipFormCell">US DOT #</div>
                  <TextField
                    name="dotNumber"
                    onChange={(e) => {
                      handleChange(e, { values, setChecked });
                    }}
                    fullWidth
                    defaultValue={dotNumber}
                    size="small"
                    variant="outlined"
                  />
                </div>
                {checked.reminders && (
                  <>
                    <div className="tooltipCSZCell">
                      <div className="tooltipZipInput">Completed</div>
                      <DatePicker
                        name="usDotCompleted"
                        className="DontClose"
                        disabled={reminderDates.USDOT.isHidden ? true : false}
                        value={reminderDates.USDOT.completedDate}
                        sx={{ width: 300 }}
                        slotProps={{
                          popper: {
                            className: "DontClose",
                            placement: "bottom",
                            sx: { zIndex: "1500" },
                          },
                          textField: { size: "small" },
                        }}
                        onChange={(date) =>
                          handleCompletedDateChange(date, "usDotCompleted")
                        }
                      />
                    </div>
                    <div className="tooltipZip">
                      <div className="tooltipFormCell">Due</div>
                      <DatePicker
                        name="usDotDue"
                        className="DontClose"
                        value={reminderDates.USDOT.dueDate}
                        onChange={(date) =>
                          handleDueDateChange(date, "usDotDue")
                        }
                        disabled={
                          reminderDates.USDOT.completedDate ? false : true
                        }
                        sx={{ width: 300 }}
                        slotProps={{
                          popper: {
                            className: "DontClose",
                            placement: "bottom",
                            sx: { zIndex: "1500" },
                          },
                          textField: { size: "small" },
                        }}
                      />
                    </div>
                    <ProgramsForm>
                      <ProgramsLabel
                        control={
                          <Checkbox
                            sx={{
                              "&.Mui-checked": {
                                color: "#21357B",
                              },
                            }}
                            checked={!reminderDates.USDOT.isHidden}
                            name="usDotIsHidden"
                            onChange={(e) =>
                              handleCompanyRemindersCheckboxChange(e, "USDOT")
                            }
                          />
                        }
                        label="Applicable"
                      />
                      <ProgramsLabel
                        control={
                          <Checkbox
                            sx={{
                              "&.Mui-checked": {
                                color: "#21357B",
                              },
                            }}
                            checked={Boolean(
                              reminderDates.USDOT.isLinscotManaged
                            )}
                            name="usDotIsLinscotManaged"
                            disabled={
                              reminderDates.USDOT.isHidden ? true : false
                            }
                            onChange={(e) => {
                              setReminderDates((prevState) => ({
                                ...prevState,
                                USDOT: {
                                  ...prevState.USDOT,
                                  isLinscotManaged: e.target.checked,
                                },
                              }));
                            }}
                          />
                        }
                        label="Linscot Managed"
                      />
                    </ProgramsForm>
                  </>
                )}
              </div>
              {/* Conditionally Render Reminders inputs if "Reminders" program is checked */}
              {checked.reminders && (
                <>
                  <div className="tooltipCSZRow">
                    <div className="tooltipCSZCell">
                      <div className="tooltipFormCell">UCR</div>
                      <TextField
                        name="uCR"
                        onChange={(e) => {
                          handleChange(e, { values, setChecked });
                        }}
                        fullWidth
                        defaultValue={uCR}
                        size="small"
                        variant="outlined"
                      />
                    </div>
                    <div className="tooltipCSZCell">
                      <div className="tooltipZipInput">Completed</div>
                      <DatePicker
                        name="ucrCompleted"
                        className="DontClose"
                        disabled={reminderDates.UCR.isHidden ? true : false}
                        value={reminderDates.UCR.completedDate}
                        sx={{ width: 300 }}
                        slotProps={{
                          popper: {
                            className: "DontClose",
                            placement: "bottom",
                            sx: { zIndex: "1500" },
                          },
                          textField: { size: "small" },
                        }}
                        onChange={(date) =>
                          handleCompletedDateChange(date, "ucrCompleted")
                        }
                      />
                    </div>
                    <div className="tooltipZip">
                      <div className="tooltipFormCell">Due</div>
                      <DatePicker
                        name="ucrDue"
                        className="DontClose"
                        onChange={(date) => handleDueDateChange(date, "ucrDue")}
                        disabled={
                          reminderDates.UCR.completedDate ? false : true
                        }
                        value={reminderDates.UCR.dueDate}
                        sx={{ width: 300 }}
                        slotProps={{
                          popper: {
                            className: "DontClose",
                            placement: "bottom",
                            sx: { zIndex: "1500" },
                          },
                          textField: { size: "small" },
                        }}
                      />
                    </div>
                    <ProgramsForm>
                      <ProgramsLabel
                        control={
                          <Checkbox
                            sx={{
                              "&.Mui-checked": {
                                color: "#21357B",
                              },
                            }}
                            checked={!reminderDates.UCR.isHidden}
                            name="UCRIsHidden"
                            onChange={(e) =>
                              handleCompanyRemindersCheckboxChange(e, "UCR")
                            }
                          />
                        }
                        label="Applicable"
                      />
                      <ProgramsLabel
                        control={
                          <Checkbox
                            sx={{
                              "&.Mui-checked": {
                                color: "#21357B",
                              },
                            }}
                            checked={Boolean(
                              reminderDates.UCR.isLinscotManaged
                            )}
                            name="UCRIsLinscotManaged"
                            disabled={reminderDates.UCR.isHidden ? true : false}
                            onChange={(e) => {
                              setReminderDates((prevState) => ({
                                ...prevState,
                                UCR: {
                                  ...prevState.UCR,
                                  isLinscotManaged: e.target.checked,
                                },
                              }));
                            }}
                          />
                        }
                        label="Linscot Managed"
                      />
                    </ProgramsForm>
                  </div>
                  <div className="tooltipCSZRow">
                    <div className="tooltipCSZCell">
                      <div className="tooltipFormCell">RIN</div>
                      <TextField
                        name="rIN"
                        onChange={(e) => {
                          handleChange(e, { values, setChecked });
                        }}
                        fullWidth
                        defaultValue={rIN}
                        size="small"
                        variant="outlined"
                      />
                    </div>
                    <div className="tooltipCSZCell">
                      <div className="tooltipZipInput">Completed</div>
                      <DatePicker
                        name="rinCompleted"
                        className="DontClose"
                        disabled={reminderDates.RIN.isHidden ? true : false}
                        value={reminderDates.RIN.completedDate}
                        sx={{ width: 300 }}
                        slotProps={{
                          popper: {
                            className: "DontClose",
                            placement: "bottom",
                            sx: { zIndex: "1500" },
                          },
                          textField: { size: "small" },
                        }}
                        onChange={(date) =>
                          handleCompletedDateChange(date, "rinCompleted")
                        }
                      />
                    </div>
                    <div className="tooltipZip">
                      <div className="tooltipFormCell">Due</div>
                      <DatePicker
                        name="rinDue"
                        className="DontClose"
                        onChange={(date) => handleDueDateChange(date, "rinDue")}
                        disabled={
                          reminderDates.RIN.completedDate ? false : true
                        }
                        value={reminderDates.RIN.dueDate}
                        sx={{ width: 300 }}
                        slotProps={{
                          popper: {
                            className: "DontClose",
                            placement: "bottom",
                            sx: { zIndex: "1500" },
                          },
                          textField: { size: "small" },
                        }}
                      />
                    </div>
                    <ProgramsForm>
                      <ProgramsLabel
                        control={
                          <Checkbox
                            sx={{
                              "&.Mui-checked": {
                                color: "#21357B",
                              },
                            }}
                            checked={!reminderDates.RIN.isHidden}
                            name="RINIsHidden"
                            onChange={(e) =>
                              handleCompanyRemindersCheckboxChange(e, "RIN")
                            }
                          />
                        }
                        label="Applicable"
                      />
                      <ProgramsLabel
                        control={
                          <Checkbox
                            sx={{
                              "&.Mui-checked": {
                                color: "#21357B",
                              },
                            }}
                            checked={Boolean(
                              reminderDates.RIN.isLinscotManaged
                            )}
                            name="RINIsLinscotManaged"
                            disabled={reminderDates.RIN.isHidden ? true : false}
                            onChange={(e) => {
                              setReminderDates((prevState) => ({
                                ...prevState,
                                RIN: {
                                  ...prevState.RIN,
                                  isLinscotManaged: e.target.checked,
                                },
                              }));
                            }}
                          />
                        }
                        label="Linscot Managed"
                      />
                    </ProgramsForm>
                  </div>
                  <div className="tooltipCSZRow">
                    <div className="tooltipCSZCell">
                      <div className="tooltipFormCell">CAB Card</div>
                      <TextField
                        name="cabCard"
                        onChange={(e) => {
                          handleChange(e, { values, setChecked });
                        }}
                        fullWidth
                        defaultValue={cabCard}
                        size="small"
                        variant="outlined"
                      />
                    </div>
                    <div className="tooltipCSZCell">
                      <div className="tooltipZipInput">Completed</div>
                      <DatePicker
                        name="cabCardCompleted"
                        className="DontClose"
                        disabled={reminderDates.CAB.isHidden ? true : false}
                        value={reminderDates.CAB.completedDate}
                        sx={{ width: 300 }}
                        slotProps={{
                          popper: {
                            className: "DontClose",
                            placement: "bottom",
                            sx: { zIndex: "1500" },
                          },
                          textField: { size: "small" },
                        }}
                        onChange={(date) =>
                          handleCompletedDateChange(date, "cabCardCompleted")
                        }
                      />
                    </div>
                    <div className="tooltipZip">
                      <div className="tooltipFormCell">Due</div>
                      <DatePicker
                        name="cabCardDue"
                        className="DontClose"
                        value={reminderDates.CAB.dueDate}
                        onChange={(date) =>
                          handleDueDateChange(date, "cabCardDue")
                        }
                        disabled={
                          reminderDates.CAB.completedDate ? false : true
                        }
                        sx={{ width: 300 }}
                        slotProps={{
                          popper: {
                            className: "DontClose",
                            placement: "bottom",
                            sx: { zIndex: "1500" },
                          },
                          textField: { size: "small" },
                        }}
                      />
                    </div>
                    <ProgramsForm>
                      <ProgramsLabel
                        control={
                          <Checkbox
                            sx={{
                              "&.Mui-checked": {
                                color: "#21357B",
                              },
                            }}
                            checked={!reminderDates.CAB.isHidden}
                            name="CABIsHidden"
                            onChange={(e) =>
                              handleCompanyRemindersCheckboxChange(e, "CAB")
                            }
                          />
                        }
                        label="Applicable"
                      />

                      <ProgramsLabel
                        control={
                          <Checkbox
                            sx={{
                              "&.Mui-checked": {
                                color: "#21357B",
                              },
                            }}
                            checked={Boolean(
                              reminderDates.CAB.isLinscotManaged
                            )}
                            name="CABIsLinscotManaged"
                            disabled={reminderDates.CAB.isHidden ? true : false}
                            onChange={(e) => {
                              setReminderDates((prevState) => ({
                                ...prevState,
                                CAB: {
                                  ...prevState.CAB,
                                  isLinscotManaged: e.target.checked,
                                },
                              }));
                            }}
                          />
                        }
                        label="Linscot Managed"
                      />
                    </ProgramsForm>
                  </div>
                  <div className="tooltipCSZRow">
                    <div className="tooltipCSZCell">
                      <div className="tooltipFormCell">2290</div>
                      <TextField
                        name="twentyTwoNinety"
                        onChange={(e) => {
                          handleChange(e, { values, setChecked });
                        }}
                        fullWidth
                        defaultValue={twentyTwoNinety}
                        size="small"
                        variant="outlined"
                      />
                    </div>
                    <div className="tooltipCSZCell">
                      <div className="tooltipZipInput">Completed</div>
                      <DatePicker
                        name="twentyTwoNinetyCompleted"
                        className="DontClose"
                        disabled={reminderDates["2290"].isHidden ? true : false}
                        value={reminderDates["2290"].completedDate}
                        sx={{ width: 300 }}
                        slotProps={{
                          popper: {
                            className: "DontClose",
                            placement: "bottom",
                            sx: { zIndex: "1500" },
                          },
                          textField: { size: "small" },
                        }}
                        onChange={(date) =>
                          handleCompletedDateChange(
                            date,
                            "twentyTwoNinetyCompleted"
                          )
                        }
                      />
                    </div>
                    <div className="tooltipZip">
                      <div className="tooltipFormCell">Due</div>
                      <DatePicker
                        name="twentyTwoNinetyDue"
                        className="DontClose"
                        value={reminderDates["2290"].dueDate}
                        onChange={(date) =>
                          handleDueDateChange(date, "twentyTwoNinetyDue")
                        }
                        disabled={
                          reminderDates["2290"].completedDate ? false : true
                        }
                        sx={{ width: 300 }}
                        slotProps={{
                          popper: {
                            className: "DontClose",
                            placement: "bottom",
                            sx: { zIndex: "1500" },
                          },
                          textField: { size: "small" },
                        }}
                      />
                    </div>
                    <ProgramsForm>
                      <ProgramsLabel
                        control={
                          <Checkbox
                            sx={{
                              "&.Mui-checked": {
                                color: "#21357B",
                              },
                            }}
                            checked={!reminderDates["2290"].isHidden}
                            name="2290IsHidden"
                            onChange={(e) =>
                              handleCompanyRemindersCheckboxChange(e, "2290")
                            }
                          />
                        }
                        label="Applicable"
                      />
                      <ProgramsLabel
                        control={
                          <Checkbox
                            sx={{
                              "&.Mui-checked": {
                                color: "#21357B",
                              },
                            }}
                            checked={Boolean(
                              reminderDates["2290"].isLinscotManaged
                            )}
                            name="2290IsLinscotManaged"
                            disabled={
                              reminderDates["2290"].isHidden ? true : false
                            }
                            onChange={(e) => {
                              setReminderDates((prevState) => ({
                                ...prevState,
                                2290: {
                                  ...prevState["2290"],
                                  isLinscotManaged: e.target.checked,
                                },
                              }));
                            }}
                          />
                        }
                        label="Linscot Managed"
                      />
                    </ProgramsForm>
                  </div>
                  <div className="tooltipUSHAZRow">
                    <div className="tooltipCSZCell">
                      <div className="tooltipFormCell">HZMT Reg.</div>
                      <TextField
                        name="usDotHazmatRegistration"
                        onChange={(e) => {
                          handleChange(e, { values, setChecked });
                        }}
                        fullWidth
                        defaultValue={usDotHazmatRegistration}
                        size="small"
                        variant="outlined"
                      />
                    </div>
                    <div className="tooltipCSZCell">
                      <div className="tooltipZipInput">Completed</div>
                      <DatePicker
                        name="hazmatRegistrationCompleted"
                        className="DontClose"
                        disabled={reminderDates.USHAZ.isHidden ? true : false}
                        value={reminderDates.USHAZ.completedDate}
                        sx={{ width: 175 }}
                        slotProps={{
                          popper: {
                            className: "DontClose",
                            placement: "bottom",
                            sx: { zIndex: "1500" },
                          },
                          textField: { size: "small" },
                        }}
                        onChange={(date) =>
                          handleCompletedDateChange(
                            date,
                            "hazmatRegistrationCompleted"
                          )
                        }
                      />
                    </div>
                    <div className="tooltipDueCell">
                      <div className="tooltipDueText">Due</div>
                      <DatePicker
                        name="hazmatRegistrationDue"
                        className="DontClose"
                        value={reminderDates.USHAZ.dueDate}
                        onChange={(date) =>
                          handleDueDateChange(date, "hazmatRegistrationDue")
                        }
                        disabled={
                          reminderDates.USHAZ.completedDate ? false : true
                        }
                        sx={{ width: 175 }}
                        slotProps={{
                          popper: {
                            className: "DontClose",
                            placement: "bottom",
                            sx: { zIndex: "1500" },
                          },
                          textField: { size: "small" },
                        }}
                      />
                    </div>
                    <div className="tooltipUSHAZCell">
                      <div className="tooltipUSHAZInput">Interval</div>
                      <Select
                        slotProps={{
                          popover: {
                            className: "DontClose",
                            sx: { zIndex: "1500" },
                          },
                        }}
                        MenuProps={{
                          sx: { zIndex: "1500" },
                          anchorOrigin: {
                            vertical: "bottom",
                            horizontal: "left",
                          },
                          transformOrigin: {
                            vertical: "top",
                            horizontal: "left",
                          },
                          MenuListProps: {
                            className: "DontClose",
                          },
                        }}
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={hazmatRegistrationInterval}
                        className="DontClose"
                        sx={textFieldStyle}
                        onChange={handleYearChange}
                        size="small"
                        name="hazmatRegistrationInterval"
                      >
                        <MenuItem className="DontClose" value={1}>
                          1 Year
                        </MenuItem>
                        <MenuItem className="DontClose" value={2}>
                          2 Years
                        </MenuItem>
                        <MenuItem className="DontClose" value={3}>
                          3 Years
                        </MenuItem>
                      </Select>
                    </div>
                    <ProgramsForm>
                      <ProgramsLabel
                        control={
                          <Checkbox
                            sx={{
                              "&.Mui-checked": {
                                color: "#21357B",
                              },
                            }}
                            checked={!reminderDates.USHAZ.isHidden}
                            name="USHAZIsHidden"
                            onChange={(e) =>
                              handleCompanyRemindersCheckboxChange(e, "USHAZ")
                            }
                          />
                        }
                        label="Applicable"
                      />
                      <ProgramsLabel
                        control={
                          <Checkbox
                            sx={{
                              "&.Mui-checked": {
                                color: "#21357B",
                              },
                            }}
                            checked={Boolean(
                              reminderDates.USHAZ.isLinscotManaged
                            )}
                            name="USHAZIsLinscotManaged"
                            disabled={
                              reminderDates.USHAZ.isHidden ? true : false
                            }
                            onChange={(e) => {
                              setReminderDates((prevState) => ({
                                ...prevState,
                                USHAZ: {
                                  ...prevState.USHAZ,
                                  isLinscotManaged: e.target.checked,
                                },
                              }));
                            }}
                          />
                        }
                        label="Linscot Managed"
                      />
                    </ProgramsForm>
                  </div>
                </>
              )}
              <div className="tooltipFormRow">
                <div className="tooltipFormCell">Notes</div>
                <TextField
                  name="companyNotes"
                  onChange={(e) => {
                    handleChange(e, { values, setChecked });
                  }}
                  fullWidth
                  size="small"
                  variant="outlined"
                  multiline
                  defaultValue={companyNotes}
                />
              </div>
              {updatedAt && updatedBy ? (
                <div className="tooltipFormRow">
                  <span className="lastModified">
                    Last Modified at {updatedAt} by {updatedBy}
                  </span>
                </div>
              ) : (
                <Fragment></Fragment>
              )}
              <div
                id="saveOrCancel"
                style={{ marginTop: "auto", paddingBottom: "10px" }}
              >
                <SaveOrCancel
                  onClick={(e) => {
                    handleCancelOrSave(e, {
                      isDetailsOpen,
                    });
                  }}
                  name="cancel"
                  variant="contained"
                >
                  Cancel
                </SaveOrCancel>
                <SaveOrCancel
                  onClick={(e) => {
                    saveReminders();
                    handleCancelOrSave(e, {
                      isDetailsOpen,
                      detailsOpen,
                      actionCategory,
                      values,
                    });
                    isDetailsOpen(false);
                    handleAlertSuccess();
                  }}
                  name="save"
                  variant="contained"
                  disabled={!isAdmin}
                >
                  Save
                </SaveOrCancel>
              </div>
            </LocalizationProvider>
          </div>
        }
        open={detailsOpen}
      >
        {children}
      </StyledDetailsTooltip>
    );
  } else if (
    actionCategory === "Employee" &&
    Object.keys(formInfo).length > 0
  ) {
    return (
      <EmployeesTooltip
        props={{
          actionCategory,
          checked,
          formInfo,
          isDetailsOpen,
          handleAlertSuccess,
          detailsOpen,
          handleChange,
          handleCancelOrSave,
          values,
          setChecked,
        }}
      >
        {children}
      </EmployeesTooltip>
    );
  } else if (
    actionCategory === "Training" &&
    Object.keys(formInfo).length > 0
  ) {
    let {
      companyName,
      instructor,
      date,
      title,
      attendees,
      updatedAt,
      updatedBy,
    } = formInfo;
    return (
      <StyledDetailsTooltip
        title={
          <div ref={tooltipRef} className="tooltipFormBox">
            <h3>
              {companyName}'s {actionCategory} Details
            </h3>
            <div className="tooltipTitleInstructorRow">
              <div className="tooltipFormCell">Training Name</div>
              <TextField
                name="title"
                onChange={(e) => {
                  handleChange(e, { values, setChecked });
                }}
                fullWidth
                defaultValue={title || ""}
                size="small"
                variant="outlined"
              />
            </div>
            <div className="tooltipFormRow">
              <div className="tooltipFormCell">Training Courses</div>
              <Autocomplete
                multiple
                className="DontClose"
                size="small"
                options={allCourses.map((course) => course.courseName)}
                isOptionEqualToValue={(option, value) => option === value}
                value={selectedCourses}
                onChange={(event, newValue) => {
                  setSelectedCourses(newValue);
                }}
                slotProps={{
                  popper: {
                    className: "DontClose",
                    sx: { zIndex: "1500" },
                  },
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    className="DontClose"
                    variant="outlined"
                    label="Select courses"
                    placeholder="Courses"
                  />
                )}
                renderTags={(value, getTagProps) =>
                  value.map((option, index) => (
                    <Chip
                      className="DontClose"
                      variant="outlined"
                      label={option}
                      {...getTagProps({ index })}
                    />
                  ))
                }
                fullWidth
              />
              <ChildModal
                style={{ zIndex: "1550" }}
                className={"DontClose"}
                setAllCourses={setAllCourses}
              ></ChildModal>
            </div>
            <div className="tooltipTitleInstructorRow">
              <div className="tooltipFormCell">Instructor</div>
              <Select
                size="small"
                fullWidth
                name="companyName"
                className="DontClose"
                sx={textFieldStyle}
                defaultValue={instructor || ""}
                onChange={(e) => {
                  values.current.instructor = e.target.value;
                }}
                slotProps={{
                  popover: {
                    className: "DontClose",
                    sx: { zIndex: "1500" },
                  },
                }}
                MenuProps={{
                  sx: { zIndex: "1500" },
                  anchorOrigin: {
                    vertical: "bottom",
                    horizontal: "left",
                  },
                  transformOrigin: {
                    vertical: "top",
                    horizontal: "left",
                  },
                }}
              >
                {allInstructors.map(({ firstName, lastName }, index) => (
                  <MenuItem
                    className="DontClose"
                    key={index}
                    value={`${firstName} ${lastName}`}
                  >
                    {`${firstName} ${lastName}`}
                  </MenuItem>
                ))}
              </Select>
            </div>
            <div className="tooltipFormRow">
              <div className="tooltipDateCell">Date</div>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  name="date"
                  className="DontClose"
                  value={dayjs.tz(date, "US/Central")}
                  sx={{ width: 300 }}
                  slotProps={{
                    popper: {
                      className: "DontClose",
                      placement: "bottom",
                      sx: { zIndex: "1500" },
                    },
                  }}
                  onChange={(e) => {
                    handleChange(e, { values, setChecked });
                  }}
                />
              </LocalizationProvider>
            </div>
            <div className="tooltipFormRow">
              <div className="tooltipAttendeesCell">Attendees</div>
              <EmployeeTransferList
                attendees={attendees}
                allEmployees={allEmployees}
                onChange={updateAttendees}
              />
            </div>
            {updatedAt && updatedBy ? (
              <div className="tooltipFormRow">
                <span className="lastModified">
                  Last Modified at {updatedAt} by {updatedBy}
                </span>
              </div>
            ) : (
              <Fragment></Fragment>
            )}
            <div
              id="saveOrCancel"
              style={{ marginTop: "auto", paddingBottom: "10px" }}
            >
              <SaveOrCancel
                onClick={(e) => {
                  handleCancelOrSave(e, {
                    isDetailsOpen,
                  });
                }}
                name="cancel"
                variant="contained"
              >
                Cancel
              </SaveOrCancel>
              <SaveOrCancel
                onClick={(e) => {
                  values.current.courses = selectedCourses;
                  values.current.attendees = filterAndMapArray(totalAttendees);
                  handleCancelOrSave(e, {
                    isDetailsOpen,
                    detailsOpen,
                    actionCategory,
                    values,
                  });
                  isDetailsOpen(false);
                  handleAlertSuccess();
                }}
                name="save"
                variant="contained"
                disabled={!isAdmin}
              >
                Save
              </SaveOrCancel>
            </div>
          </div>
        }
        open={detailsOpen}
      >
        {children}
      </StyledDetailsTooltip>
    );
  }
}
